import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
})

export function useEagerConnect(shouldEagerConnect) {
  const { activate, active } = useWeb3React()

  useEffect(() => {
    if (shouldEagerConnect && !active) {
      activate(injected)
    }
  }, [activate, active, shouldEagerConnect])
}
