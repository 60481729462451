import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useEagerConnect } from './hooks/useEagerConnect';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';
import DisconnectModal from './DisconnectModal';
import InputNumber from './InputNumber';
import abi from './contracts/abi.json';
import rightImage from './right.jpeg';
import middleImage from './1k.gif';
import { injected } from './connectors';

const contractAddress = '0x8578b174f4F1F5Be1Ca312A0A649199D38121bE8';
const RPC = 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
const provider = new ethers.providers.JsonRpcProvider(RPC);

export default function Home() {
  const context = useWeb3React();
  const { activate, deactivate, active, error } = context;
  const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [qtyRequested, setQtyRequested] = useState(1);

  useEffect(() => {
    const ping = async () => {
      const contract = new ethers.Contract(contractAddress, abi, provider);

      const _totalSupply = await contract.totalSupply();
      const _maxSupply = await contract.MAX_SUPPLY();

      setTotalSupply(Number(_totalSupply));
      setMaxSupply(Number(_maxSupply));
    };
    setInterval(ping, 500);
  }, []);

  const publicMint = async () => {
    if (!context.account) {
      setErrorMessage('Connect your wallet first');
    }
    const { library } = context;
    const signer = library.getSigner();
    const gasPrice = Number(await provider.getGasPrice());
    const contract = new ethers.Contract(
      contractAddress,
      abi,
      signer,
      {
        from: context.account,
        gasPrice: gasPrice,
      },
    );
    try {
      const tx = await contract.mint(qtyRequested, {
        value: ethers.utils.parseEther((qtyRequested * 0.02).toString()),
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onWalletConnect = async () => {
    if (window.ethereum == undefined) {
      window.open('https://metamask.io/', '_blank');
      setErrorMessage(
        'you need metamask or any other dapp extension to connect',
      );
    }
    activate(injected);
  };

  const onWalletDisconnect = async () => {
    setDisconnectModalOpen(true);
    console.log(isDisconnectModalOpen);
  };

  return (
    <div className='App'>
      <main className='App-main'>
        <div className='grid'>
          <div className='grid-item menu'>
            <div className='App-navbar__right'>
              {active && (
                <button className='button' onClick={onWalletDisconnect}>
                  {context.account.substring(0, 5)}...
                  {context.account.substring(context.account.length - 4)}
                </button>
              )}
              {!active && (
                <button className='button' onClick={onWalletConnect}>
                  Connect Wallet
                </button>
              )}
              <div className='mintButton'>
                <button
                  className='button mints'
                  onClick={publicMint}
                >
                  <div className='button-container'>
                    <span className='title'>
                      PUBLIC MINT
                      <div className='button-floater'>
                        {qtyRequested * 0.02} ETH
                      </div>
                    </span>
                  </div>
                </button>
                <InputNumber
                  value={qtyRequested}
                  onChange={setQtyRequested}
                  min={1}
                  max={200}
                />
              </div>
              {errorMessage && (
                <div className='error-message'>{errorMessage}</div>
              )}
              <div className='box'>
                <h1 className='supply'>
                  {maxSupply === 0
                    ? 'Fetching supply'
                    : maxSupply - totalSupply + ' / ' + maxSupply}
                </h1>
              </div>
            </div>
          </div>
          <div className='grid-item menu flex-end'>
            <img src='/fatality.jpg_small' />
          </div>
          <div className='grid-item'>
            <img src='/nasdaq.jpg_medium' />
          </div>
          <div className='grid-item flex-end'>
            <img src='/wu.jpg_large' />
          </div>
        </div>
      </main>
      {isDisconnectModalOpen && (
        <DisconnectModal
          show={isDisconnectModalOpen}
          onClose={() => setDisconnectModalOpen(false)}
        />
      )}
    </div>
  );
}
