import HttpsRedirect from 'react-https-redirect';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from '@web3-react/core'
import Home from './Home';

import logo from './logo.svg';
import './App.css';

function getLibrary(provider, connector) {
  return new Web3Provider(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Home />
    </Web3ReactProvider>
  )
}

export default App;
