import React from 'react';

const InputNumber = ({ value, onChange, min, max }) => {
  const handleChange = (e) => {
    onChange(Number(e.target.value));
  };

  return (
    <div className='input-number'>
      <input
        className='input-number__input'
        type='number'
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputNumber;
